* {
  padding: 0;
  margin: 0;
  font-family: "Noto Sans", sans-serif;
}

.newImg {
  object-fit: cover;
  height: 220px;
}
.seen_date {
  margin: 30px 0px;
}
.news_icon {
  color: #06456a;
  font-size: 30px;
  display: block;
  width: 120px;
}
.for_icon {
  margin-left: -40px;
  margin-bottom: 0;
}
.saylov {
  overflow: hidden;
}
.saylov img {
  width: 300px;
  margin: auto;
  margin-bottom: 30px;
  border: 2px solid grey;
}
.mahhalla {
  padding: 100px 0;
}
.mahhalla h1 {
  color: #06456a;
  text-align: center;
  padding-bottom: 50px;
}
.list-group-tabs-example {
  background: #06456a;
}
