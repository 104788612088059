* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: "Noto Sans", sans-serif;
}

.m-7 {
  margin: 7px !important;
}

.link-style {
  color: #fff;
  text-decoration: none;
}

.link-style:hover {
  color: #fff;
}

.text-container-style img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 100px 0; */
}

.text-container-style {
  margin: auto;
  margin-top: 50px;
  transition: 0.5s;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px rgba(120, 119, 119, 0.2);
}

.text-container-style:hover {
  box-shadow: 2px 2px 5px 1px rgba(120, 119, 119, 0.3);
}

.card_right {
  position: relative;
  top: 0;
  left: 0;
}

/* .card_important{
  position: sticky;
  top: 0;
  left: 0;
} */
.news-text-style {
  text-align: center;
  font-size: 35px;
  padding: 20px 0;
}

.news-fulltext-style {
  text-align: justify;
  padding: 0 50px;
  font-size: 20px;
  height: 80%;
}

.news-back-style {
  color: #06456a;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

@media (max-width: 1199px) {
  .news-id {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 425px) {
  .newcard123 {
    width: 100%;
  }
  .c {
    margin-top: 3rem !important;
  }

  .text-container-style {
    width: 100%;
  }

  .newsCardImg {
    height: 250px;
  }
}
