@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500&display=swap");

* {
  font-family: "Noto Sans", sans-serif;
}

.header {
  width: 100%;
  height: auto;
  font-weight: normal;
  font-family: "Raleway", sans-serif;
}

.header a {
  color: white;
}

.carousel .carousel-indicators li {
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

.carousel:hover {
  background: white;
  opacity: 0.8;
}

.row-first {
  width: 100%;
  height: 70px;
  /* background: #06456a; */
  background: white;
}

.search {
  display: inline;
  background: #fff;
  transition: 1s;
}

form {
  display: flex;
  background: white;
  border-radius: 32px;
  overflow: hidden;
}

.search {
  margin: auto;
}

.search input {
  display: block;
  width: 90%;
  height: 30px;
  background: #fff;
  padding: 10px;
  font-weight: normal;
  font-size: 18px;
  color: #000;
  border: none;
  outline: none;
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  line-height: 15px;
}

.search input::placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.search_button:focus {
  outline: none;
}

.search_icon {
  color: white;
  width: 13%;
  height: 22px;
}

.social-icons {
  display: inline;
}

.icon {
  background: none;
  color: #06456a;
  height: 25px;
  margin-left: 10px;
  width: 100%;
}

.row-second {
  height: auto;
}

.navbar {
  background: white;
  box-shadow: 1px 1px 1px rgb(121, 115, 115);
  /* height: 70px; */
  /* overflow: hidden; */
  /* display: flex; */
  /* align-items: center; */
}

.navjs {
  /* display: block;
  overflow: auto; */
}

.navbar ul {
  /* width: 100%; */
  /* margin-top: 13px; */
  /* min-width: 696px; */

  margin: 0;
}

.navbar ul li a {
  display: block;
  color: #06456a;
  font-weight: 500;
  font-size: 16px;
  font-family: "Raleway", sans-serif;
}

.navbar ul li a:active {
  display: block;
  color: red;
  font-weight: 600;
  font-size: 16px;
  font-family: "Raleway", sans-serif;
}

.navbar ul li a:hover {
  color: rgb(177, 177, 177);
}

.logo2 {
  /* margin-bottom: 10px; */
}

.logo1,
.logo2 {
  display: block;
  height: 50px;
}

.dropdown {
  display: inline;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 4px;
}

.menu {
  display: none;

}

.menu_button {
  color: black;
  font-size: 40px;
  cursor: pointer;
}

.close {
  color: black;
  position: absolute;
  left: 87.78%;
  right: 8.61%;
  top: 5.62%;
  bottom: 92.34%;
}

.myslider {
  z-index: 0;
}

.img_box p {
  color: black;
  font-size: 25px;
  font-weight: 500;
  font-family: "Raleway";
}

.rang {
  color: white;
  font-size: 30px;
}

.rang:hover {
  color: white;
}

.mycard2 {
  width: 100%;
  border-radius: 5px;
  transition: 1s;
  overflow: hidden;
}

.col-md-6 img {
  transition: 1s;
}

.col-md-6 img:hover {
  transform: scale(1);
}

.mycard2:hover {
  box-shadow: 0px 4px 22px 1px rgba(0, 0, 0, 0.5);
}

@media (max-width: 1199px) {
  .logo2,
  .logo1 {
    display: none;
  }

  form {
    background: rgba(0, 0, 0, 0);
    padding: 5px;
  }

  .search {
    margin-left: 60px;
  }

  .search input {
    background: rgba(0, 0, 0, 0);
  }

  .search input::placeholder {
    color: white;
  }

  .search_icon {
    display: block;
  }

  .social-icons {
    display: none;
  }

  .menu {
    display: block;
  }

  .navjs {
    display: none;
  }
}

@media (max-width: 768px) {
  .search {
    display: none;
  }

  .social-icons {
    display: none;
  }
}
