@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');   
*{
    font-family: 'Noto Sans', sans-serif;
}
.footer{
    width: 100%;
    padding: 30px 0 40px;
    background: #06456A;
    margin-top: 50px;
    padding-bottom: 60px;
}
.footer_h2{
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    color: white ;
}
.phone{
    font-size: 26px;
    color: white;
    margin-right: 20px;
}
.footer_text p, .footer_text a{
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: white;
    text-decoration: none;
}
.footer_menu {
    height: 140px;
}

.footer_menu ul{
    padding: 0;
}
.footer_menu li a{
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none;
    color: white ;
}
.footer_menu li a:hover{
    text-decoration: underline;
}
.footer_end{
    width: 100%;
    height: 2px;
    background: blanchedalmond;
    margin-top: 20px;
}
.footer_end p{
    font-family: 'Inter';
    font-size: 15px;
    color: white;
    margin-top: 10px;
}
.aloqa-footer{
    font-weight: 700;
}
.teamit-footer{
    font-weight: 600;
}