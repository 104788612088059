*{
    font-family: 'Noto Sans', sans-serif;
}
.tashkilot{
    padding: 10px 5px;
    background: rgb(241, 240, 240);
    margin-top: 15px;
    transition: .5s;
    border-radius: 8px;
}
.tashkilot:hover{
    box-shadow: 0px 4px 22px 1px rgb(0 0 0 / 50%);
}
.tashkilotlar h1{
    display: flex;
    justify-content: center;
    font-size: 38px;
}
.tashkilotlar a{
    text-decoration: none;
}
.tashkilotlar h4{
    font-size: 23px;
}
.tashkilotlar p{
    font-size: 18px;
}
.tashkilotlar{
    background: white;
}