@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500&display=swap");

* {
  font-family: "Noto Sans", sans-serif;
}

.main_top {
  margin-top: 70px;
}

.main_top h1 {
  color: #117ab6;
  font-size: 32px;
  font-family: "Raleway";
  font-weight: 500;
}

.card {
  overflow: hidden;
  box-sizing: border-box;
  text-decoration: none;
  padding-bottom: 18px;
}

.newsCardImg {
  transform: scale(1);
  transition: 0.5s;
  filter: brightness(0.7);
  height: 230px;
  object-fit: cover;
}

.newsCardImg:hover {
  transform: scale(1.1);
  filter: brightness(0.5);
}

.news_top {
  top: 12px;
  left: 12px;
  background: #06456a;
  position: absolute;
  border-radius: 4px;
  padding: 3px 8px;
}

.news_top p {
  color: white;
  margin: 0;
  padding: 0;
}

.news {
  padding: 15px;
  color: black;
  text-decoration: none;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3; /* 3 qator bilan cheklash */
  line-clamp: 3;
  height: calc(2em * 3); /* 3 qatorning maksimal balandligi */
  line-height: 1.7em; /* Har bir qatorning balandligini sozlash */
}

.Sidebar {
  padding: 10px;
}

.newsDz {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.dzImage {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 5px;
}

.newsDzLink {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 14px;
  color: black;
  text-decoration: none;
  padding: 5px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3; /* 3 qator bilan cheklash */
  line-clamp: 3;
  height: calc(1.6em * 3); /* 3 qatorning maksimal balandligi */
  line-height: 1.5em; /* Har bir qatorning balandligini sozlash */
}

@media (max-width: 1024px) {
  .newsCardImg {
    height: 200px;
  }

  .cards_right img {
    width: 40%;
    height: 100%;
  }

  .cards_right p {
    left: 45%;
  }

  .card_right {
    display: block;
  }
}

/* @media(max-width: 768px) {
  .card_right {
   display: none;
  }
} */

@media (max-width: 425px) {
  .c {
    margin-top: 3rem !important;
  }

  .newsCardImg {
    height: 250px;
  }
}
