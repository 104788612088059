@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500&display=swap');
*{
    font-family: 'Noto Sans', sans-serif;
}
.swiper{
    height: 300px;
    margin-top: 50px;
    padding: 100px 30px;
}
.main{
    padding: 30px 0px;
}
.swiper a{
    text-decoration: none;
    height: 300px;
}
.swiper-container{
    height: 200px;
}
.mycard{
    position: relative;
    height: 350px;
    border: none;
}
.mycard img{
    display: block;
    width: 100%;
    height: 80%;
    position: absolute;
    top: 0;
    left: 0;
    z-index:0;
}
.mycard_bg{
    width: 100%;
    height: 100%;
}
.card_body{
    position: absolute;
    bottom: 70px;
    left: 30px;
    width: 75%;
    z-index:0;
}
.ml-2{
    color: white;
}

.card_body p{
    color:white;
}

.mycard .card_top{
    width: 170px;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    background: #06456A;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-img-top{
    width: 100%;
    height: 250px;
}
#img{
    width: 100%;
    height: 90%;
}
.mycard2{
    display: flex;
    padding:25px;
    height: auto;
    display: flex;
    background: white;
    margin: auto;
}
.mycard a{
    color: white;
    text-decoration: none;
    font-size: 15px;
}
.mycard a:hover{
    text-decoration: underline;
}
.card_body2{
    height: 160px;
}

.card_body2 h5{
    font-size: 14px;
    font-weight: 500;
    color:blue;
}
.card_body2 p{
    font-size: 12px;
    font-weight: 400;
    color:black;
    width: 95%;
}
.card_body2 p {
    margin: 0;
}
.more{
    display: flex;
    justify-content:flex-end;
}
.main h1{
    font-size: 36px;
    font-weight: 500;
    color: #0d6efd;
}
.main_section h2{
    font-size: 36px;
    font-weight: 500;
    color: blue;
}
.mycard_2_a {
    color: black;
    text-decoration: none;
    font-size: 15px;
}
.mycard_2_a:hover{
    text-decoration: underline;
    color: black;
}
.swiper a .img_content{
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;
    color: black;
}
@media(max-width: 1024px){
    .mycard2 #img{
        height: 60%;
    }
    .card_body2 h5{
        font-size: 11px;
    }
    .card_body2 p{
        font-size: 9px;
    }
    .main{
        display: none;
    }
}
@media(max-width: 768px) {
    .swiper a .img_content{
        font-size: 10px;
    }
}
@media(max-width: 425px) {
    .swiper a .img_content{
        font-size: 8px;
    }
    
}