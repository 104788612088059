* {
  font-family: "Noto Sans", sans-serif;
}

.slider {
  width: 100%;
  height: 100%;
  /* max-height: 600px; */
  background: black;
  text-decoration: underline;
}

.slider img {
  width: 100%;
  object-fit: cover;
  background-color: white;
}

.slider-container {
  margin: 0 auto;
  width: 100%;
  /* have to have a height */
  height: 800px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
}

article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.6s ease-in-out;
  display: flex;
  justify-content: center;
  transform: scale(0);
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}

article.lastSlide {
  transform: translateX(-100%);
}

article.nextSlide {
  transform: translateX(100%);
}

.prev {
  position: relative;
  background: none;
  color: rgba(255, 255, 255, 0.746);
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 65px;
  cursor: pointer;
  transition: all 0.3s linear;
}

.next {
  position: relative;
  background: none;
  color: rgba(255, 255, 255, 0.746);
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 65px;
  cursor: pointer;
  transition: all 0.3s;
}

.title {
  width: 80%;
  cursor: pointer;
  position: absolute;
  color: white;
  text-decoration: none;
  font-size: 35px;
  bottom: 100px;
  text-shadow: 1px 1px 5px #000000;
}

.title:hover {
  text-decoration: underline;
  color: white;
}

.line {
  width: 80%;
  height: 5px;
  background: white;
  position: absolute;
  margin-top: 510px;
}

article:hover {
  transform: scale(1.1);
  filter: brightness(90%);
}

@media (max-width: 1024px) {
  .title {
    font-size: 25px;
    margin-top: 235px;
  }

  .slider-container {
    height: 400px;
  }

  .slider img {
    height: 400px;
  }

  .line {
    margin-top: 350px;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 20px;
    margin-top: 135px;
  }

  .slider-container {
    height: 300px;
  }

  .slider img {
    height: 300px;
  }

  .line {
    margin-top: 250px;
  }
}

@media (max-width: 425px) {
  .title {
    font-size: 10px;
    margin-top: 100px;
    width: 70%;
  }

  .slider-container {
    height: 200px;
  }

  .slider img {
    height: 200px;
  }

  .line {
    display: none;
  }

  .prev,
  .next {
    font-size: 25px;
  }
}
